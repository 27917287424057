import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const StyledCard = styled(motion.div)`
    display: block;
    flex-shrink: 0;
    
    position: relative;
    
    width: calc(100vw - 9rem);
    max-width: 35rem;

    margin-right: 6rem;

    &:last-of-type {
        margin-right: 0;
    }
    
    ${props => props.theme.above.desktop`
        width: 75rem;
        max-width: none;
        
        margin-right: 10rem;

        &:hover {
            .image {
                transform: scale(1.05);
            }
        }
    `}
`

const StyledLink = styled(Link)`
    display: block;
`

const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    
    max-height: 35rem;

    background-color: ${props => props.theme.colors.darkMedium};

    margin-bottom: 2rem;

    overflow: hidden;

    ${props => props.theme.above.desktop`
        height: 50rem;
        max-height: none;

        margin-bottom: 3rem;
    `}
`

const StyledImage = styled(Img)`
    width: 100%;
    height: 100%;

    transition: transform 2s ${props => props.theme.transitions.cubic};
`

const Description = styled.div`
    height: 2rem;

    overflow: visible;

    text-align: left;

    ${props => props.theme.above.desktop`
        text-align: center;

        height: auto;
    `}
`

const Title = styled.h3`
    font-size: 2rem;
    line-height: 2.5rem;

    ${props => props.theme.above.desktop`
        font-size: 3rem;
        line-height: 3.5rem;
    `}
`

const Excerpt = styled.p``

const Card = ({
    to,
    className,
    animate,
    data: {
        about_title,
        about_excerpt,
        about_image
    }
}) => {
    return (
        <StyledCard 
            className={className}
            animate={animate}
            initial={{
                opacity: 0
            }}
            variants={{
                before: {
                    y: -100,
                    opacity: 0
                },
                after: {
                    y: 0,
                    opacity: 1
                }
            }}
            transition={{
                duration: 1,
                damping: 100,
                stiffness: 100,
                ease: 'circOut'
            }}
        >
            <StyledLink to={to}>
                <ImageWrapper>
                    {about_image[0].image && <StyledImage className='image' fluid={about_image[0].image.fluid} alt={about_title.text} objectFit='cover' />}
                </ImageWrapper>
                <Description>
                    <Title>{about_title.text}</Title>
                    <Excerpt>{about_excerpt.text}</Excerpt>
                </Description>
            </StyledLink>
        </StyledCard>
    )
}

export default Card