import React, { useRef, useEffect, useState } from "react"
import { useWindowSize } from "use-hooks"
import { useStaticQuery, graphql } from "gatsby"
import gsap from "gsap"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import styled from "styled-components"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

import { generatePath } from "./../../utils/helpers"

import Title from "./../Title"
import ButtonCircle from "./../Buttons/ButtonCircle"

const TitleWithButtonWrapper = styled.div`
    text-align: center;

    .title-wrapper {
        margin-bottom: 2.5rem;
    }

    .button-circle {
        display: inline-block;
    }

    ${props => props.theme.below.desktop`
        margin-top: 2.5rem;

        text-align: center;
        visibility: visible;
        
        .title-wrapper {
            text-align: center;
        }
    `}

    ${props => props.theme.above.desktop`
        margin-bottom: 15rem;

        .title-wrapper {
            margin-bottom: 0;
        }
    `}
`

const StyledLink = styled(Link)`
    display: block;
`

const StyledImage = styled(Img)`
    margin-bottom: 3rem;
    max-height: 420px;
`

const Header = styled.div`
    margin-bottom: 3rem;

    text-align: center;

    ${props => props.theme.above.desktop`
        margin-bottom: 0;
    `}
`

const Plant = styled.h4`
    display: block;

    margin-bottom: 0.5rem;

    font-size: 1.4rem;
    line-height: 1.8rem;

    ${props => props.theme.above.desktop`
        font-size: 2.5rem;
        line-height: 3rem;
    `}
`

const Family = styled.span`
    display: block;

    font-size: 1.1rem;
    line-height: 1.2rem;

    ${props => props.theme.above.desktop`
        font-size: 1.3rem;
        line-height: 1.5rem;
    `}
`

const SectionVaried = ({ data, lang }) => {
    const VARIED_SECTION_WRAPPER = useRef(null)
    const VARIED_CAPTION = useRef(null)
    const VARIED_SLIDER = useRef(null)
    const VARIED_SLIDER_WRAPPER = useRef(null)
    const VARIED_SLIDER_DECOR = useRef(null)
    const VARIED_BUTTON = useRef(null)

    const getSliderHeight = () => {
        return VARIED_SLIDER_WRAPPER.current
            ? VARIED_SLIDER_WRAPPER.current.clientHeight
            : 0
    }

    const [sliderHeight, setSliderHeight] = useState(getSliderHeight())

    let line
    const timeline = () => {
        // console.log(window.outerHeight - VARIED_SLIDER.current.clientHeight)
        line = gsap
            .timeline({
                scrollTrigger: {
                    trigger: VARIED_SECTION_WRAPPER.current,
                    start: `middle ${window.outerHeight / 2 -
                        VARIED_SLIDER.current.clientHeight / 2}px`,
                    end: () => "+=600%",
                    scrub: true,
                    pin: true,
                    pinSpacing: false,
                    anticipatePin: 1,
                },
            })
            .set(VARIED_SLIDER.current, {
                autoAlpha: 0,
                xPercent: "100",
                yPercent: "100",
            })
            // .set(VARIED_BUTTON.current, {
            //     autoAlpha: 0
            // })
            .set(
                [VARIED_CAPTION.current, VARIED_BUTTON.current],
                {
                    yPercent: "100",
                    autoAlpha: 0,
                },
                "together"
            )
            .to(
                VARIED_CAPTION.current.querySelector(
                    ".gradient-opacity-text--45"
                ),
                {
                    backgroundPosition: "20% 0",
                    duration: "0.5",
                },
                "together"
            )
            .to(
                [VARIED_CAPTION.current, VARIED_BUTTON.current],
                {
                    yPercent: "75",
                    duration: 2,
                    autoAlpha: 0,
                },
                "together"
            )

            .to([VARIED_CAPTION.current, VARIED_BUTTON.current], {
                yPercent: "35",
                duration: 2,
                autoAlpha: 1,
            })

            .to(
                [VARIED_CAPTION.current, VARIED_BUTTON.current],
                {
                    yPercent: "0",
                    duration: 2,
                    autoAlpha: 0,
                },
                "+=4"
            )
            .set([VARIED_CAPTION.current, VARIED_BUTTON.current], {
                display: "none",
            })
            // .to(VARIED_BUTTON.current, {
            //     autoAlpha: 1,
            //     duration: "0.1"
            // }, 'together-2')
            .set(VARIED_SLIDER.current, {
                yPercent: "0",
                duration: "0.1",
            })
            .to(VARIED_SLIDER.current, {
                yPercent: "0",
                autoAlpha: 1,
                duration: "0.25",
            })
            .set(VARIED_CAPTION.current, {
                autoAlpha: 0,
            })
            .to(
                VARIED_SLIDER_WRAPPER.current,
                {
                    xPercent: "-180",
                    duration: data.length + 2,
                },
                "-=1"
            )
        // .to(VARIED_SLIDER.current, {
        //     yPercent: "-100",
        //     autoAlpha: 0,
        //     duration: "0.5"
        // })
        // .to(VARIED_SLIDER_DECOR.current, {
        //     bottom: "-1%",
        //     duration: "0.5"
        // });
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger)
        }

        gsap.defaults({
            ease: "none",
        })

        setSliderHeight(getSliderHeight())

        setSliderHeight(VARIED_SLIDER.current.clientHeight)

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function() {
                timeline()
            },
        })

        window.addEventListener("resize", () => {
            if (line && line.scrollTrigger && line.scrollTrigger.start) {
                // console.log(window.outerHeight/2 - VARIED_SLIDER.current.clientHeight/2)
                line.vars.scrollTrigger.start = `top ${window.outerHeight / 2 -
                    VARIED_SLIDER.current.clientHeight / 2}px`
            }

            setSliderHeight(VARIED_SLIDER.current.clientHeight)
        })
    }, [])

    return (
        <section className="section-varied">
            <div className="section-varied__wrapper is-mobile">
                <AliceCarousel
                    buttonsDisabled={true}
                    dotsDisabled={true}
                    duration={500}
                    autoPlay={false}
                    fadeOutAnimation={true}
                    responsive={{
                        0: {
                            items: 2,
                        },
                    }}
                >
                    {data.map(({ highlighted_product }, i) => {
                        const data = highlighted_product.document.data
                        const slug = `collection/${data.product_family.slug}/${highlighted_product.document.uid}`

                        return (
                            <StyledLink
                                to={generatePath(lang, slug)}
                                className="section-varied__decor"
                                key={i}
                            >
                                <StyledImage
                                    className="picture-image picture-varied"
                                    fluid={data.small.fluid}
                                    alt={data.small.alt}
                                />
                                <Header>
                                    <Plant className="title">
                                        {data.product_title.text}
                                    </Plant>
                                    <Family className="family">
                                        {
                                            data.product_family.document.data
                                                .title.text
                                        }
                                    </Family>
                                </Header>
                            </StyledLink>
                        )
                    })}
                </AliceCarousel>
                <TitleWithButtonWrapper>
                    <Title
                        title="I’m available in different colours and sizes. Which one suits you the best?"
                        alignment="center"
                        className="gradient-opacity-text--45"
                        scroll
                    />
                    <div ref={VARIED_BUTTON}>
                        <ButtonCircle
                            type="arrow"
                            direction="right"
                            label="Discover the collection"
                            animation="after"
                            to={generatePath(lang, `collection`)}
                            alignment="center"
                        />
                    </div>
                </TitleWithButtonWrapper>
            </div>
            <div
                ref={VARIED_SECTION_WRAPPER}
                className="section-varied__wrapper is-desktop"
            >
                <div ref={VARIED_CAPTION}>
                    <TitleWithButtonWrapper>
                        <Title
                            title="I’m available in different colours and sizes. Which one suits you the best?"
                            alignment="center"
                            className="gradient-opacity-text--45"
                            scroll
                        />
                        <div ref={VARIED_BUTTON}>
                            <ButtonCircle
                                type="arrow"
                                direction="right"
                                label="Discover the collection"
                                animation="after"
                                to={generatePath(lang, `collection`)}
                                alignment="center"
                            />
                        </div>
                    </TitleWithButtonWrapper>
                </div>
                <div ref={VARIED_SLIDER} className="section-varied__container">
                    <div
                        ref={VARIED_SLIDER_WRAPPER}
                        className="section-varied__inner"
                    >
                        {data.map(({ highlighted_product }, i) => {
                            const data = highlighted_product.document.data
                            const slug = `collection/${data.product_family.slug}/${highlighted_product.document.uid}`

                            return (
                                <StyledLink
                                    to={generatePath(lang, slug)}
                                    className="section-varied__decor"
                                    key={i}
                                >
                                    <StyledImage
                                        className="picture-image picture-varied"
                                        fluid={data.small.fluid}
                                        alt={data.small.alt}
                                    />
                                    <Header>
                                        <Plant className="title">
                                            {data.product_title.text}
                                        </Plant>
                                        <Family className="family">
                                            {
                                                data.product_family.document
                                                    .data.title.text
                                            }
                                        </Family>
                                    </Header>
                                </StyledLink>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionVaried

// Old timeline

// const timeline = () => {
//     // console.log(window.outerHeight - VARIED_SLIDER.current.clientHeight)
//     line = gsap
//     .timeline({
//         scrollTrigger: {
//             trigger: VARIED_SECTION_WRAPPER.current,
//             start: `middle ${window.outerHeight/2 - VARIED_SLIDER.current.clientHeight/2}px`,
//             end: () => "+=600%",
//             scrub: true,
//             pin: true,
//             pinSpacing: false,
//             anticipatePin: 1
//         }
//     })
//     .set(VARIED_SLIDER.current, {
//         autoAlpha: 0,
//         xPercent: '100',
//         yPercent: '100'
//     })
//     // .set(VARIED_BUTTON.current, {
//     //     autoAlpha: 0
//     // })
//     .set([VARIED_CAPTION.current,VARIED_BUTTON.current], {
//         yPercent: '100',
//         autoAlpha: 0,
//     },'together')
//     .to(VARIED_CAPTION.current.querySelector('.gradient-opacity-text--45'), {
//         backgroundPosition: "20% 0",
//         duration: "0.5"
//     },  'together')
//     .to([VARIED_CAPTION.current,VARIED_BUTTON.current], {
//         yPercent: '75',
//         duration:2,
//         autoAlpha:0,
//     }, 'together')

//     .to([VARIED_CAPTION.current,VARIED_BUTTON.current], {
//         yPercent: '35',
//         duration:2,
//         autoAlpha:1,
//     })

//     .to([VARIED_CAPTION.current,VARIED_BUTTON.current], {
//         yPercent: '0',
//         duration:2,
//         autoAlpha:0,
//     }, '+=4')
//     .set([VARIED_CAPTION.current,VARIED_BUTTON.current], {   display:'none'})
//     // .to(VARIED_BUTTON.current, {
//     //     autoAlpha: 1,
//     //     duration: "0.1"
//     // }, 'together-2')
//     .to(VARIED_SLIDER.current, {
//         autoAlpha: 1,
//         yPercent: '0',
//         duration: "0.1"
//     }, 'together-2')
//     .to(VARIED_SLIDER.current, {
//         yPercent: '0',
//         duration: "1.5"
//     })
//     .set(VARIED_CAPTION.current, {
//         autoAlpha: 0,
//     })
//     .to(VARIED_SLIDER_WRAPPER.current, {
//         xPercent: '-180',
//         duration: data.length+2,
//     })
//     // .to(VARIED_SLIDER.current, {
//     //     yPercent: "-100",
//     //     autoAlpha: 0,
//     //     duration: "0.5"
//     // })
//     // .to(VARIED_SLIDER_DECOR.current, {
//     //     bottom: "-1%",
//     //     duration: "0.5"
//     // });
// }
