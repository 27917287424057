import React, { useState, useEffect, useContext } from "react"
import { Waypoint } from "react-waypoint"

import { graphql } from "gatsby"
import { InView } from "react-intersection-observer"
import { scroller } from "react-scroll"
import styled from "styled-components"

import Seo from "./../components/Layout/Seo"
import HeroBanner from "./../components/HeroBanner"
import Manifest from "./../components/Manifest"
import FeaturedCollection from "./../components/Collection/Featured"
import About from "./../components/About/Page"
import History from "./../components/History/Page"
import ButtonCircle from "./../components/Buttons/ButtonCircle"
import InstagramFeed from "./../components/Instagram/InstagramFeed"
import ScrollAnimation from "../components/Home/ScrollAnimation"
import { DictionaryContext } from "../contexts/dictionary"

const StyledButtonCircleMobile = styled(ButtonCircle)`
    position: fixed;

    left: 50%;
    bottom: 6rem;

    z-index: 2;

    transform: translateX(-50%);

    .label {
        display: none;
    }

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const StyledButtonCircleDesktop = styled(ButtonCircle)`
    display: none;

    ${props => props.theme.above.desktop`
        display: block;

        position: fixed;

        left: 5rem;
        bottom: 3rem;

        z-index: 2;

        ${props.visible == "false" &&
            `
            display: none;
        `}
    `}
`

const StyledHistory = styled(History)`
    .generations-introduction {
        background-color: ${props => props.theme.colors.darkMedium};
    }

    ${props => props.theme.above.desktop`
        .generations-introduction {
            background-color: transparent;
        }
    `}
`

const IndexPage = ({
    pageContext: { lang, langSlug },
    data: {
        aboutItems,
        instagram,
        theme: {
            data: { seo_title, seo_description, seo_keywords, seo_image },
        },
        index: { data },
    },
}) => {
    const {
        page_title,
        slideshow,
        hero_title,
        button_label,
        social_links,
    } = data

    const dictionary = useContext(DictionaryContext)

    const scrollData = {
        intro: {
            subtitle: data.intro_group[0].intro_subtitle,
            title: data.intro_group[0].intro_title.text,
            contentTitle: data.intro_group[0].intro_content_title.text,
            description: data.intro_group[0].intro_content_description,
        },
        thumb: {
            title: data.thumb_content_title.text,
            description: data.thumb_content_description,
        },
        collection: {
            title: data.collection_title.text,
            buttonLabel: data.collection_button_label,
        },
        winter: {
            title: data.winter_content_title.text,
            description: data.winter_content_description,
        },
        butterflies: {
            title: data.butterflies_content_title.text,
            description: data.butterflies_content_description,
        },
        usps: {
            title: data.usps_title.text,
            buttonLabel: data.usps_button_label,
            items: data.usp_items,
        },
        video: {
            playLabel: data.video_play_label,
            description: data.video_description,
            buttonLabel: data.buy_button_label,
        },
        buy: {
            title: data.buy_content_title.text,
            description: data.buy_content_description,
            buttonLabel: data.buy_button_label,
        },
    }

    
    const [desktopButtonVisible, setDesktopButtonVisible] = useState(true)
    const [mobileButtonVisible, setMobileButtonVisible] = useState(true)

    const handleScroll = () => {
        setMobileButtonVisible(window.scrollY <= 100)
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
          
            <HeroBanner
                lang={langSlug}
                title={hero_title}
                button={button_label}
                social={social_links}
                slideshow={slideshow}
            />
            <ScrollAnimation lang={langSlug} data={scrollData} />
            
            <InstagramFeed copy={instagram} />
                <StyledButtonCircleDesktop
                    type="arrow"
                    direction="down"
                    label={dictionary.explore_more}
                    animation={desktopButtonVisible ? "after" : "before"}
                    onClick={() => {
                        scroller.scrollTo("animation", {
                            offset: -1,
                            duration: 1500,
                            delay: 100,
                            smooth: "easeInOutQuint",
                        })
                    }}
                />
        </>
    )
}

export const QUERY_INDEXPAGE = graphql`
    query IndexPage($id: String!, $lang: String!) {
        index: prismicIndexPage(id: { eq: $id }) {
            id
            lang
            ...IndexPageQuery
        }
        aboutItems: allPrismicAboutItem(filter: { lang: { eq: $lang } }) {
            edges {
                node {
                    uid
                    data {
                        about_title {
                            text
                        }
                        about_excerpt {
                            text
                        }
                        about_image {
                            image {
                                alt
                                fluid(maxWidth: 800) {
                                    ...GatsbyPrismicImageFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        instagram: prismicInstagramComponent(lang: { eq: $lang }) {
            id
            lang
            ...InstagramQuery
        }
        theme: prismicTheme(lang: { eq: $lang }) {
            id
            lang
            ...ThemeQuery
        }
    }
`

export default IndexPage
