import React from "react"

import "./main.css"

import SectionLiving from "./SectionLiving"
import SectionCare from "./SectionCare"
import SectionVaried from "./SectionVaried"
import SectionCollection from "./SectionCollection"
import SectionWinter from "./SectionWinter"
import SectionInsect from "./SectionInsect"
import SectionKnow from "./SectionKnow"
import SectionStory from "./SectionStory"
import SectionBuy from "./SectionBuy"

const ScrollAnimation = ({ lang, data }) => {
    return (
        <div name="animation" className="scroll-animation">
            <SectionLiving data={data.intro} />
            <SectionCare data={data.thumb} />
            {/* <SectionVaried lang={lang} data={highlighted_items} /> */}
            <SectionCollection lang={lang} data={data.collection} />
            <SectionWinter data={data.winter} />
            <SectionInsect data={data.butterflies} />
            <SectionKnow lang={lang} data={data.usps} />
            <SectionStory data={data.video} />
            <SectionBuy data={data.buy} />
        </div>
    )
}

export default ScrollAnimation
