import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import gsap from "gsap"
import styled from "styled-components"
import Img from "gatsby-image"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"

import { generatePath } from "../../utils/helpers"

import IconBee from "./../../graphics/home/icon-bee.svg"
import IconCalendar from "./../../graphics/home/icon-calendar.svg"
import IconClimate from "./../../graphics/home/icon-climate.svg"
import IconColors from "./../../graphics/home/icon-colors.svg"
import IconFlowers from "./../../graphics/home/icon-flowers.svg"
import IconWinter from "./../../graphics/home/icon-winter.svg"

import GifCalendar from "./../../images/home/gifs/calendar.gif"
import GifClimate from "./../../images/home/gifs/climate.gif"
import GifColors from "./../../images/home/gifs/colors.gif"
import GifFlowers from "./../../images/home/gifs/flowers.gif"
import GifWinter from "./../../images/home/gifs/winter.gif"
import GifBee from "./../../images/home/gifs/bee.gif"

import Title from "./../Title"
import ButtonCircle from "./../Buttons/ButtonCircle"

const TitleWithButtonWrapper = styled.div`
    text-align: center;

    .title-wrapper {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    .button-circle {
        display: inline-block;
    }

    ${props => props.theme.below.desktop`
        text-align: left;

        .button-circle {
            padding: 3rem 0;
        }
    `}

    ${props => props.theme.above.desktop`
       
    `}
`

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200vh;

    .section-know__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .section-know__wrapper.is-mobile {
        display: none;
    }

    .section-know__wrapper.is-desktop {
        display: flex;
    }

    .section-know__text {
        .title-wrapper {
            padding-left: 0;
        }

        h1 {
            color: #fff;
        }
    }
    .section-know__container {
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 1.5625rem; */
        visibility: hidden;
        max-width: none;
        width: 100%;
    }
    .section-know__center-image {
        position: absolute;
        pointer-events: none;
        z-index: 5;
        /* padding-top: 1.375em; */

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .section-know__center-border {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 50em;
        height: 50em;
        border: 1px solid rgba(255, 255, 255, 0.15);
        -webkit-border-radius: 50%;
        border-radius: 50%;
        pointer-events: none;

        transition: border-color 0.35s ease-out;
    }
    .section-know__center-image div {
        flex: 1;

        width: 60rem;
        margin: 0 auto;
    }
    .section-know__center-image img {
        position: relative;
    }
    /* .section-know__center-image:hover::before {
        border-color: white;
    } */
    .section-know__part-left,
    .section-know__part-right {
        /* padding-top: 5.625em; */
        width: 20em;
        position: relative;
        z-index: 5;
    }
    .section-know__description-left,
    .section-know__description-right {
        position: relative;
        padding-bottom: 1em;
        font-family: "NeueMontrealRegular", Arial, sans-serif;
        font-size: 1.6rem;
        line-height: 2rem;
        color: #fff;
    }
    .section-know__description-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .section-know__icon-wrapper {
            width: 4rem;

            text-align: center;
            margin-right: 2rem;
        }

        .icon--gif {
            display: none;
        }

        /* &:hover {
            .icon {
                display: none;

                &--gif {
                    display: block;
                }
            }
        } */
    }
    .section-know__description-left .section-know__description-inner {
        justify-content: flex-start;
    }
    .section-know__description-right {
        .section-know__icon-wrapper {
            margin-right: 0;
            margin-left: 2rem;
        }
    }
    .section-know__description-left:nth-child(n + 2),
    .section-know__description-right:nth-child(n + 2) {
        margin-top: 3.5em;
    }
    .section-know__description-left:before,
    .section-know__description-right:before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 10em;
        z-index: 1;
        background-color: #fff;
        transition: width 0.35s ease-out;
    }
    .section-know__description-left:after,
    .section-know__description-right:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.15);
    }
    .section-know__description-left:nth-child(3):after {
        width: 20em;
    }
    .section-know__description-left:nth-child(3):hover:before {
        width: 20em;
    }
    .section-know__description-left:hover:before {
        width: 18em;
    }
    .section-know__description-right:hover:before {
        width: 18em;
    }
    .icon {
        /* margin-right: 2rem; */
        display: inline-block;
        vertical-align: middle;

        path {
            fill: white;
        }
    }
    .icon-calendar {
        width: 2em;
        /* height: 1.875em; */
    }
    .icon-winter {
        width: 2em;
        /* height: 1.9375em; */
    }
    .icon-climate {
        width: 2em;
        /* height: 1.6875em; */
    }
    .icon-colors {
        .a {
            fill: #ffa5b5;
        }
    }
    .section-know__description-left:before {
        left: 0;
    }
    .section-know__description-left:after {
        left: 0;
        width: 18em;
    }
    .section-know__description-right {
        margin-left: auto;
        text-align: right;

        .icon {
            /* margin-left: 2rem; */
            margin-right: 0;
        }
    }
    .section-know__description-right:nth-child(3):after {
        width: 20em;
    }
    .section-know__description-right:nth-child(3):hover:before {
        width: 20em;
    }
    .icon-flowers {
        width: 2em;
        /* height: 2.25em; */
    }
    .icon-bee {
        width: 2em;
        /* height: 1.8125em; */
    }
    .icon-colors {
        margin-left: 5px;
        width: 2em;
        /* height: 1.9375em; */
    }
    .section-know__description-right:before {
        right: 0;
    }
    .section-know__description-right:after {
        right: 0;
        width: 18em;
    }
    .section-know__description-label {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.25;
        color: #fff;
    }

    .section-know__center-image {
        .picture-plant {
            position: absolute;

            &--center {
                position: relative;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        height: auto;
        .section-know__wrapper {
            padding-top: 4rem;
            padding-bottom: 4rem;
            padding-left: 3rem;
            padding-right: 3rem;
            min-height: 0;
        }

        .section-know__wrapper.is-mobile {
            display: block;
            margin-bottom: 5rem;
        }

        .section-know__wrapper.is-desktop {
            display: none;
        }

        .section-know__wrapper.is-mobile .button-circle {
            margin-top: 2.5rem;
        }

        .section-know__wrapper .title-wrapper {
            text-align: center;
        }

        .section-know__mobile-image {
            margin-bottom: 2.5em;
        }

        .section-know__mobile-list {
            width: 100%;
            margin: 0 auto;
            max-width: 25em;
            /* margin-bottom: 5rem; */
        }

        .section-know__text {
            width: 100%;
            visibility: visible;
        }
        .section-know__container {
            font-size: 1rem;
            visibility: visible;
        }
        .section-know__description-inner {
            justify-content: flex-start;
        }
        .section-know__center-image {
            position: relative;
        }
        /* .section-know__center-image:before {
            top: 0;
            content:none;
        } */
        .section-know__part-left,
        .section-know__part-right {
            padding-top: 4em;
            width: 10em;
        }
        .section-know__description-left {
            padding-left: 0;
            padding-bottom: 2.5em;
            flex: 1;
        }

        .section-know__description-left:before {
            content: none;
        }
        .section-know__description-left:after {
            width: 12em;
        }
        .section-know__description-left:nth-child(3):after {
            width: 16em;
        }
        .section-know__description-right,
        .section-know__description-left {
            padding-right: 0;
            padding-bottom: 1em;
            flex: 1;
        }
        .section-know__description-right .section-know__description-inner {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        .section-know__description-right .section-know__description-inner svg {
            margin-left: 0;
            margin-right: 0;
        }
        .section-know__description-right:before {
            content: none;
        }
        .section-know__description-right:after {
            width: 12em;
        }
        .section-know__description-right:nth-child(3):after {
            width: 16em;
        }
        /* .icon-colors {
            width: auto !important;
        } */

        .section-know__description-right {
            .section-know__icon-wrapper {
                margin-right: 0;
                margin-left: 0;
            }
        }
        .section-know__icon-wrapper {
            margin-right: 4rem;
        }
    }
    @media (max-width: 1024px) {
        .section-know__container {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-top: 3rem;
        }

        .section-know__part-left,
        .section-know__part-right {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding-top: 0;
            width: auto;
        }
        .section-know__description-left:nth-child(n + 2),
        .section-know__description-right:nth-child(n + 2) {
            margin-top: 0;
        }
        .section-know__description-left:after,
        .section-know__description-right:after {
            display: none;
        }
        .section-know__description-right {
            padding-right: 0;
            padding-left: 0;
            text-align: left;
        }
        .section-know__description-right:before {
            right: auto;
            left: 0;
        }

        .section-know__center-image div:nth-child(3) .picture-image {
            position: relative;

            transform: translate(-63%, -8%) !important;
            z-index: -1;
        }

        .section-know__center-image div:nth-child(2) .picture-image {
            position: relative;

            transform: scale(1.5) !important;
            z-index: 0;
        }

        .section-know__center-image div:nth-child(1) .picture-image {
            position: relative;

            transform: translate(59%, -9%) scale(0.92) !important;
            z-index: 0;
        }
    }
    @media (max-width: 570px) {
        .section-know__wrapper {
            width: 100%;
            padding: 2rem 1.25rem;
        }
        .section-know__text {
            padding-left: 0;
        }
        .section-know__container {
            margin-top: 2rem;
            font-size: 1rem;
        }
        .section-know__center-image {
            margin-top: 2em;

            height: auto;
        }
        /* .section-know__center-image:before {
            left: 0;
           
        } */
        .section-know__center-image div:nth-child(3) .picture-image {
            position: relative;

            transform: translate(-63%, -8%) !important;
            z-index: -1;
        }

        .section-know__center-image div:nth-child(2) .picture-image {
            position: relative;

            transform: scale(1.5) !important;
            z-index: 0;
        }

        .section-know__center-image div:nth-child(1) .picture-image {
            position: relative;

            transform: translate(59%, -9%) scale(0.92) !important;
            z-index: 0;
        }

        .section-know__part-left,
        .section-know__part-right {
            display: block;
            width: 100%;
        }
        .section-know__part-right {
            margin-top: 3em;
        }
        .section-know__description-left,
        .section-know__description-right {
            margin-top: 0.5em;
            padding-top: 0.5em;
            max-width: 100%;
        }
        .section-know__description-left:nth-child(n + 2),
        .section-know__description-right:nth-child(n + 2) {
            margin-top: 0.5em;
        }

        .section-know__description-inner {
            justify-content: left;
        }
    }

    @media screen and (min-width: 570px) {
        .section-know__description-inner {
            &:hover {
                .icon {
                    display: none;

                    &--gif {
                        display: block;
                    }
                }
            }
        }
    }
`

const SectionKnow = ({ lang, data }) => {
    const IMAGE_REF = useRef(null)
    const KNOW_SECTION_IMAGE_BORDER = useRef(null)
    const KNOW_SECTION_WRAPPER = useRef(null)
    const KNOW_SECTION_TEXT = useRef(null)
    const KNOW_SECTION_IMAGE = useRef(null)
    // const KNOW_SECTION_IMAGE_LEFT = useRef(null);
    // const KNOW_SECTION_IMAGE_RIGHT = useRef(null);
    const KNOW_SECTION_IMAGE_MIDDLE = useRef(null)
    const KNOW_SECTION_LEFT_ITEMS = useRef([])
    const KNOW_SECTION_LEFT_ITEMS_INNER = useRef([])
    const KNOW_SECTION_RIGHT_ITEMS = useRef([])
    const KNOW_SECTION_RIGHT_ITEMS_INNER = useRef([])
    const VARIED_BUTTON = useRef([])

    const { know } = useStaticQuery(graphql`
        query {
            know: allFile(
                filter: {
                    relativePath: {
                        in: [
                            "home/images/section-know.png"
                            "home/images/section-know-left.png"
                            "home/images/section-know-center.png"
                            "home/images/section-know-right.png"
                        ]
                    }
                }
            ) {
                edges {
                    node {
                        name
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)

    const timeline = () => {
        // let leftLines = CSSRulePlugin.getRule(".section-know__description-left:before");
        // let rightLines = CSSRulePlugin.getRule(".section-know__description-right:before");

        gsap.timeline({
            scrollTrigger: {
                trigger: KNOW_SECTION_WRAPPER.current,
                start: "50% 50%",
                end: () => "+=150%",
                scrub: true,
                pin: KNOW_SECTION_WRAPPER.current,
                pinSpacing: false,
            },
        })
            .set(KNOW_SECTION_IMAGE_BORDER.current, {
                opacity: 0,
                scale: 0,
            })
            .set(
                KNOW_SECTION_TEXT.current,
                {
                    opacity: 0,
                    yPercent: -115,
                    scale: 0.75,
                },
                0
            )
            .to(
                KNOW_SECTION_TEXT.current,
                {
                    opacity: 1,
                    yPercent: -115,
                    scale: 1,
                },
                0
            )
            .to(
                KNOW_SECTION_IMAGE_BORDER.current,
                {
                    opacity: 1,
                    scale: 1,
                },
                0
            )
            .set(
                KNOW_SECTION_IMAGE.current,
                {
                    x: "0",
                    y: "0",
                    opacity: 1,
                    visibility: "visible",
                },
                0
            )
            .set(
                KNOW_SECTION_RIGHT_ITEMS.current,
                {
                    autoAlpha: 0,
                },
                0
            )
            .set(
                KNOW_SECTION_LEFT_ITEMS.current,
                {
                    autoAlpha: 0,
                },
                0
            )
            // .set(KNOW_SECTION_IMAGE_LEFT.current, {
            //     opacity: 0,
            //     translateX: "73%",
            //     zIndex: -1,
            //     scale:.6,
            //     translateY:-24
            // },0)
            // .set(KNOW_SECTION_IMAGE_RIGHT.current, {
            //     opacity: 0,
            //     translateX: "-75%",
            //     translateY:-30,
            //     scale:.6,
            //     zIndex: -1
            // },0)
            .set(
                KNOW_SECTION_IMAGE_MIDDLE.current,
                {
                    opacity: 0,
                    scale: 1,
                },
                0
            )
            // .to(KNOW_SECTION_IMAGE_LEFT.current, {
            //     opacity: 1,
            //     translateX: "73%",
            //     scale:.6,
            //     translateY:-24
            // },0)
            .to(
                KNOW_SECTION_IMAGE_MIDDLE.current,
                {
                    opacity: 1,
                    scale: 1,
                },
                0
            )
            // .to(KNOW_SECTION_IMAGE_RIGHT.current, {
            //     opacity: 1,
            //     translateX: "-75%",
            //     translateY:-30,
            //     scale:.6,
            //     zIndex: -1
            // },0)
            .set(
                KNOW_SECTION_LEFT_ITEMS.current[0],
                {
                    autoAlpha: 0,
                    translateX: "-300%",
                },
                0
            )
            .to(
                KNOW_SECTION_LEFT_ITEMS.current[0],
                {
                    autoAlpha: 1,
                    translateX: "-150%",
                },
                0
            )
            .set(
                KNOW_SECTION_RIGHT_ITEMS.current[0],
                {
                    autoAlpha: 0,
                    translateX: "300%",
                },
                0
            )
            .to(
                KNOW_SECTION_RIGHT_ITEMS.current[0],
                {
                    autoAlpha: 1,
                    translateX: "150%",
                },
                0
            )
            .set(
                KNOW_SECTION_LEFT_ITEMS.current[1],
                {
                    autoAlpha: 0,
                    translateX: "-300%",
                },
                0.33
            )
            .to(
                KNOW_SECTION_LEFT_ITEMS.current[1],
                {
                    autoAlpha: 1,
                    translateX: "-150%",
                },
                0.33
            )
            .set(
                KNOW_SECTION_RIGHT_ITEMS.current[1],
                {
                    autoAlpha: 0,
                    translateX: "300%",
                },
                0.33
            )
            .to(
                KNOW_SECTION_RIGHT_ITEMS.current[1],
                {
                    autoAlpha: 1,
                    translateX: "150%",
                },
                0.33
            )

            .set(
                KNOW_SECTION_LEFT_ITEMS.current[2],
                {
                    autoAlpha: 0,
                    translateX: "-300%",
                },
                0.66
            )
            .to(
                KNOW_SECTION_LEFT_ITEMS.current[2],
                {
                    autoAlpha: 1,
                    translateX: "-150%",
                },
                0.66
            )
            .set(
                KNOW_SECTION_RIGHT_ITEMS.current[2],
                {
                    autoAlpha: 0,
                    translateX: "300%",
                },
                0.66
            )
            .to(
                KNOW_SECTION_RIGHT_ITEMS.current[2],
                {
                    autoAlpha: 1,
                    translateX: "150%",
                },
                0.66
            )
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger, CSSRulePlugin)
        }

        gsap.defaults({
            ease: "none",
        })

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function() {
                timeline()
                hoverAnimation()
            },
        })
    }, [])

    const hoverAnimation = () => {
        let duration = 2
        let tl = gsap.timeline({ yoyo: true, repeat: -1, paused: true })

        tl.to(
            IMAGE_REF.current,
            { y: -15, duration: duration, ease: "none" },
            0
        )
        // tl.to(IMAGE_SHADE_REF.current, { scaleX: 0.5, scaleY: 0.5, duration: duration, ease: 'none' }, 0)

        tl.play()
    }

    return (
        <Wrapper className="section-know">
            <div className="section-know__wrapper is-mobile">
                <Title
                    title={data.title}
                    alignment="center"
                    className="gradient-opacity-text--45"
                    scroll
                />
                <div className="section-know__mobile-image section-mobile__image-overlay">
                    <Img
                        className="picture-image picture-plant picture-plant--center"
                        fluid={know.edges[3].node.childImageSharp.fluid}
                        alt="If you don't know me by now"
                    />
                </div>
                <div className="section-know__mobile-list">
                    <ul>
                        <li className="section-know__description-left">
                            <div className="section-know__description-inner">
                                <div className="section-know__icon-wrapper">
                                    <IconCalendar className="icon icon-calendar" />
                                    <img
                                        src={GifCalendar}
                                        className="icon icon--gif icon-calendar"
                                    />
                                </div>
                                <span className="section-know__description-label">
                                    {data.items[0].year_round}
                                </span>
                            </div>
                        </li>
                        <li className="section-know__description-left">
                            <div className="section-know__description-inner">
                                <div className="section-know__icon-wrapper">
                                    <IconWinter className="icon icon-winter" />
                                    <img
                                        src={GifWinter}
                                        className="icon icon--gif icon-winter"
                                    />
                                </div>
                                <span className="section-know__description-label">
                                    {data.items[0].winter}
                                </span>
                            </div>
                        </li>
                        <li className="section-know__description-left">
                            <div className="section-know__description-inner">
                                <div className="section-know__icon-wrapper">
                                    <IconClimate
                                        className="icon icon-climate"
                                        style={{ width: "1.35em" }}
                                    />
                                    <img
                                        src={GifClimate}
                                        className="icon icon--gif icon-climate"
                                    />
                                </div>
                                <span className="section-know__description-label">
                                    {data.items[0].maintain}
                                </span>
                            </div>
                        </li>
                        <li className="section-know__description-left">
                            <div className="section-know__description-inner">
                                <div className="section-know__icon-wrapper">
                                    <IconFlowers className="icon icon-flowers" />
                                    <img
                                        src={GifFlowers}
                                        className="icon icon--gif icon-flowers"
                                    />
                                </div>
                                <span className="section-know__description-label">
                                    {data.items[0].every_summer}
                                </span>
                            </div>
                        </li>
                        <li className="section-know__description-left">
                            <div className="section-know__description-inner">
                                <div className="section-know__icon-wrapper">
                                    <IconBee
                                        className="icon icon-bee"
                                        style={{
                                            transform:
                                                "rotate(-63deg) translate(-1px, -3px)",
                                        }}
                                    />
                                    <img
                                        src={GifBee}
                                        className="icon icon--gif icon-bee"
                                    />
                                </div>
                                <span className="section-know__description-label">
                                    {data.items[0].diversity}
                                </span>
                            </div>
                        </li>
                        <li className="section-know__description-left">
                            <div className="section-know__description-inner">
                                <div className="section-know__icon-wrapper">
                                    <IconColors className="icon icon-colors" />
                                    <img
                                        src={GifColors}
                                        className="icon icon--gif icon-colors"
                                    />
                                </div>
                                <span className="section-know__description-label">
                                    {data.items[0].colours}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <ButtonCircle
                        type="arrow"
                        direction="right"
                        label={data.buttonLabel}
                        animation="after"
                        to={generatePath(lang, `collection`)}
                        alignment="center"
                    />
                </div>
            </div>
            <div
                ref={KNOW_SECTION_WRAPPER}
                className="section-know__wrapper is-desktop"
            >
                <div ref={KNOW_SECTION_TEXT} className="section-know__text">
                    <TitleWithButtonWrapper>
                        <Title
                            title={data.title}
                            alignment="center"
                            className="gradient-opacity-text--45"
                            scroll
                        />
                        <div ref={VARIED_BUTTON}>
                            <ButtonCircle
                                type="arrow"
                                direction="right"
                                label={data.buttonLabel}
                                animation="after"
                                to={generatePath(lang, `collection`)}
                                alignment="center"
                            />
                        </div>
                    </TitleWithButtonWrapper>
                </div>
                <div
                    ref={KNOW_SECTION_IMAGE}
                    className="section-know__container"
                >
                    <div
                        ref={KNOW_SECTION_IMAGE_BORDER}
                        className="section-know__center-border"
                    ></div>
                    <ul className="section-know__part-left">
                        <li
                            ref={el =>
                                (KNOW_SECTION_LEFT_ITEMS.current[0] = el)
                            }
                            className="section-know__description-left"
                        >
                            <div
                                ref={el =>
                                    (KNOW_SECTION_LEFT_ITEMS_INNER.current[0] = el)
                                }
                                className="section-know__description-inner"
                            >
                                <div className="section-know__icon-wrapper">
                                    <IconCalendar className="icon icon-calendar" />
                                    <img
                                        src={GifCalendar}
                                        className="icon icon--gif icon-calendar"
                                    />
                                </div>
                                <span className="section-know__description-label">
                                    {data.items[0].year_round}
                                </span>
                            </div>
                        </li>
                        <li
                            ref={el =>
                                (KNOW_SECTION_LEFT_ITEMS.current[1] = el)
                            }
                            className="section-know__description-left"
                        >
                            <div
                                ref={el =>
                                    (KNOW_SECTION_LEFT_ITEMS_INNER.current[1] = el)
                                }
                                className="section-know__description-inner"
                            >
                                <div className="section-know__icon-wrapper">
                                    <IconWinter className="icon icon-winter" />
                                    <img
                                        src={GifWinter}
                                        className="icon icon--gif icon-winter"
                                    />
                                </div>
                                <span className="section-know__description-label">
                                    {data.items[0].winter}
                                </span>
                            </div>
                        </li>
                        <li
                            ref={el =>
                                (KNOW_SECTION_LEFT_ITEMS.current[2] = el)
                            }
                            className="section-know__description-left"
                        >
                            <div
                                ref={el =>
                                    (KNOW_SECTION_LEFT_ITEMS_INNER.current[2] = el)
                                }
                                className="section-know__description-inner"
                            >
                                <div className="section-know__icon-wrapper">
                                    <IconClimate
                                        className="icon icon-climate"
                                        style={{ width: "1.35em" }}
                                    />
                                    <img
                                        src={GifClimate}
                                        className="icon icon--gif icon-climate"
                                    />
                                </div>
                                <span
                                    className="section-know__description-label"
                                    style={{ marginLeft: "-9px" }}
                                >
                                    {data.items[0].maintain}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div ref={IMAGE_REF} className="section-know__center-image">
                        <div ref={KNOW_SECTION_IMAGE_MIDDLE}>
                            <Img
                                className="picture-image picture-plant picture-plant--center"
                                fluid={know.edges[3].node.childImageSharp.fluid}
                                alt="If you don't know me by now"
                            />
                        </div>
                    </div>
                    <ul className="section-know__part-right">
                        <li
                            ref={el =>
                                (KNOW_SECTION_RIGHT_ITEMS.current[0] = el)
                            }
                            className="section-know__description-right"
                        >
                            <div
                                ref={el =>
                                    (KNOW_SECTION_RIGHT_ITEMS_INNER.current[0] = el)
                                }
                                className="section-know__description-inner"
                            >
                                <span className="section-know__description-label">
                                    {data.items[0].every_summer}
                                </span>
                                <div className="section-know__icon-wrapper">
                                    <IconFlowers className="icon icon-flowers" />
                                    <img
                                        src={GifFlowers}
                                        className="icon icon--gif icon-flowers"
                                    />
                                </div>
                            </div>
                        </li>
                        <li
                            ref={el =>
                                (KNOW_SECTION_RIGHT_ITEMS.current[1] = el)
                            }
                            className="section-know__description-right"
                        >
                            <div
                                ref={el =>
                                    (KNOW_SECTION_RIGHT_ITEMS_INNER.current[1] = el)
                                }
                                className="section-know__description-inner"
                            >
                                <span className="section-know__description-label">
                                    {data.items[0].diversity}
                                </span>
                                <div className="section-know__icon-wrapper">
                                    <IconBee
                                        className="icon icon-bee"
                                        style={{
                                            transform:
                                                "rotate(-63deg) translate(-1px, -3px)",
                                        }}
                                    />
                                    <img
                                        src={GifBee}
                                        className="icon icon--gif icon-bee"
                                    />
                                </div>
                            </div>
                        </li>
                        <li
                            ref={el =>
                                (KNOW_SECTION_RIGHT_ITEMS.current[2] = el)
                            }
                            className="section-know__description-right"
                        >
                            <div
                                ref={el =>
                                    (KNOW_SECTION_RIGHT_ITEMS_INNER.current[2] = el)
                                }
                                className="section-know__description-inner"
                            >
                                <span className="section-know__description-label">
                                    {data.items[0].colours}
                                </span>
                                <div className="section-know__icon-wrapper">
                                    <IconColors className="icon icon-colors" />
                                    <img
                                        src={GifColors}
                                        className="icon icon--gif icon-colors"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </Wrapper>
    )
}

export default SectionKnow
