import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { InView, useInView } from "react-intersection-observer"
import Img from "gatsby-image"
import styled from "styled-components"

import theme from "./../styles/theme"

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    width: 100%;

    padding: 6rem 1.5rem;
    border-bottom: ${props => props.theme.borders.light};

    text-align: center;

    ${props => props.theme.above.desktop`
        height: 100vh;

        padding: 0;
        border-bottom: none;

        text-align: right;
    `}
`

const ManifestWrapper = styled(motion.div)`
    position: relative;
`

const StyledManifest = styled.h2`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 6rem;

    ${props => props.theme.above.desktop`
        margin-bottom: 0;
    `}
`

const Sentence = styled(motion.span)`
    display: block;
`

const Word = styled(motion.span)`
    font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
    font-size: 1.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.085rem;

    color: ${props => props.theme.colors.white};

    ${props => props.theme.above.desktop`
        font-size: 2rem;
        line-height: 3.5rem;
        letter-spacing: 0.12rem;
    `}
`

const Signature = styled(motion.div)`
    overflow: hidden;

    ${props => props.theme.above.desktop`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        position: absolute;
        right: 6rem;
        bottom: 6rem;
    `}
`

const ImageWrapper = styled.div`
    display: block;
    width: 18rem;
    margin: 0 auto 3rem auto;

    ${props => props.theme.above.desktop`
        width: 20rem;
        margin: 0 0 2rem 0;
    `}
`

const Image = styled(Img)`
    display: block;
    width: 100%;
    height: 100%;
`

const Title = styled.div`
    p {
        font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
        font-size: 1.3rem;
        line-height: 2rem;
        letter-spacing: 0.07rem;

        color: ${props => props.theme.colors.darkText};
    }

    ${props => props.theme.above.desktop`    
        p {
            font-size: 1.4rem;
            line-height: 2rem;

            color: ${props => props.theme.colors.white};
        }
    `}
`

const manifestVariants = {
    before: {},
    after: {
        transition: {
            delayChildren: 0.15,
            staggerChildren: 0.1
        }
    }
}

const wordVariants = {
    before: {
        opacity: 0.1
    },
    after: {
        opacity: 1,
        transition: {
            duration: 0.2
        }
    }
}

const GetSentence = ({ children, text, scroll }) => {
    
    const ref = React.createRef()

    function get_animation() {
        return !ref.current || ref.current.getBoundingClientRect().top <= window.innerHeight / 2 ? 'after' : 'before'
    }
    
    const [animation, setAnimation] = useState(get_animation())

    useEffect(() => {
        setAnimation(get_animation())
    }, [scroll, text])

    return (
        <Sentence ref={ref} animate={animation} initial={{ opacity: 0 }} variants={wordVariants}>
            {children}
        </Sentence>
    )
}

const Manifest = ({
    sectionInView,
    inViewTreshold,
    data: { 
        manifest, 
        signature 
    }
}) => {

    const [ref, inView] = useInView({
        threshold: 0.35
    })

    const [scrollTop, setScrollTop] = useState(
        (inView && typeof window != 'undefined' ) ? window.pageYOffset : 0
    )

    useEffect(() => {
        
        if (!inView) return

        function calculateWordPositions() {
            setScrollTop(inView ? window.pageYOffset : 0)
        }

        window.addEventListener('scroll', calculateWordPositions)

        return function cleanup() {
            window.removeEventListener('scroll', calculateWordPositions)
        }
    })

    const animation = inView ? 'after' : 'before'

    return (
        <Wrapper name='manifest' ref={ref}>
            <InView
                threshold={inViewTreshold}
                onChange={(inView, entry) => inView && sectionInView && sectionInView()}
            >
                <ManifestWrapper
                    animate={animation}
                    initial='before'
                    variants={{
                        before: {
                            opacity: 0
                        },
                        after: {
                            opacity: 1
                        }
                    }}
                    transition={{
                        delay: 0.5,
                        duration: 0.5,
                        damping: 100,
                        ease: 'circOut'
                    }}
                >
                    <StyledManifest>
                        {manifest.raw.map((sentence, a) => {
                            const wordArr = sentence.text.split(' ')
                            return (
                                <GetSentence 
                                    key={a}
                                    scroll={scrollTop}
                                    index={a}
                                    length={manifest.raw.length}
                                >
                                    {wordArr.map((item, b) => {
                                        return (
                                            <Word key={b}>{item}{' '}</Word>
                                        )
                                    })}
                                </GetSentence>
                            )
                        })}
                    </StyledManifest>
                </ManifestWrapper>
                <Signature
                    animate={animation}
                    initial='before'
                    variants={{
                        before: {
                            opacity: 0,
                        },
                        after: {
                            opacity: 1,
                        },
                    }}
                    transition={{
                        duration: 0.5,
                        delay: 0.5,
                        damping: 100,
                        ease: 'circOut'
                    }}
                >
                    <ImageWrapper>
                        <Image
                            fluid={signature[0].signature_image.fluid}
                            alt={signature[0].signature_image.alt}
                        />
                    </ImageWrapper>
                    <Title
                        dangerouslySetInnerHTML={{ __html: signature[0].signature_title.html }}
                    />
                </Signature>
            </InView>
        </Wrapper>
    )
}

export default Manifest
