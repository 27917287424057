import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { useInView, InView } from 'react-intersection-observer'
import { Waypoint } from 'react-waypoint'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import stickybits from 'stickybits'

import { generatePath } from './../../utils/helpers'

import ButtonCircle from './../Buttons/ButtonCircle'
import TitleWithButton from './../TitleWithButton'

const Wrapper = styled.section`
    position: relative;

    overflow: hidden;

    ${props => props.theme.above.desktop`
        padding: 20rem 0 0 0;

        border-bottom: 0;

        overflow: initial;

        font-size: 0;
    `}
`

const Header = styled(motion.div)`
    width: 100%;

    padding: 3rem 0;

    ${props => props.theme.above.desktop`
        display: inline-block;
        vertical-align: top;
        
        width: 55%;
        height: 100vh;

        margin: 0;
        padding: 0;
        
        text-align: left;
    `}
`

const StyledTitleWithButton = styled(TitleWithButton)`
    ${props => props.theme.below.desktop`
        .title {
            font-size: 3.5rem;
            line-height: 4rem;
        }
    `}

    ${props => props.theme.above.desktop`
        position: absolute;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    `}
`

const List = styled(motion.ul)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 1.5rem;

    margin-bottom: 3rem;
    padding: 0 1.5rem;

    ${props => props.theme.above.desktop`
        display: inline-block;
        vertical-align: top;

        width: 45%;

        margin: 0;
    `}
`

const Item = styled.li`
    display: block;

    text-align: center;

    ${props => props.theme.above.desktop`
        margin-bottom: 8rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            .image {
                transform: scale(1.05);
            }
        }
    `}
`

const StyledLink = styled(Link)`
    display: block;
`

const ImageWrapper = styled.div`
    margin-bottom: 2.5rem;

    background-color: ${props => props.theme.colors.darkMedium};

    overflow: hidden;

    ${props => props.theme.above.desktop`
        width: 100%;
        max-width: 60rem;

        margin: 0 auto 4rem auto;
    `}
`

const StyledImage = styled(Img)`
    width: 100%;
    height: 100%;

    transition: transform 5s ${props => props.theme.transitions.cubic};

    picture {
        img {
            opacity: 1 !important;
        }
    }
`

const Product = styled.h4`
    margin-bottom: 0.5rem;

    ${props => props.theme.below.desktop`
        margin-bottom: 0.25rem;

        font-size: 1.3rem;
        line-height: 1.8rem;
    `}
`

const Family = styled.span`
    display: block;

    ${props => props.theme.below.desktop`
        margin-bottom: 2rem;

        font-size: 1.1rem;
        line-height: 1.5rem;
    `}
`

const Bottom = styled.div`
    padding: 3rem;

    text-align: center;

    background-color: ${props => props.theme.colors.darkMedium};
`

const Featured = ({ 
    lang,
    className,
    sectionInView,
    inViewTreshold,
    data: {
        highlighted_title, 
        highlighted_description,
        highlighted_button_label,
        highlighted_items
    }
}) => { 

    const [currentIndex, setCurrentIndex] = useState(0)
    const headerRef = useRef(null)

    const [ref, inView] = useInView({
        threshold: 0.15,
        triggerOnce: true
    })
    
    const itemEnter = (index) => {
        setCurrentIndex(index + 1)
    }

    useEffect(() => {
        stickybits(headerRef.current)
    }, [])

    return (
        <Wrapper ref={ref} className={className} name='featured'>
            <InView treshold={inViewTreshold} onChange={(inView) => inView && sectionInView && sectionInView()}>
                <Header ref={headerRef} className='header'>
                    <StyledTitleWithButton
                        lang={lang} 
                        indication={{
                            current: currentIndex,
                            total: highlighted_items.length
                        }}
                        title={highlighted_title.text}
                        description={highlighted_description.html}
                        button={{
                            type: 'arrow',
                            direction: 'right',
                            label: highlighted_button_label,
                            to: 'collection'
                        }}
                        animate={inView ? 'after' : 'before'}
                    />
                </Header>
                <List
                    animate={inView ? 'after' : 'before'}
                    variants={{
                        before: {
                            opacity: 0,
                            x: 100      
                        },
                        after: {
                            opacity: 1,
                            x: 0
                        }
                    }}
                    transition={{
                        duration: 0.55,
                        damping: 100,
                        stiffness: 100,
                        ease: 'circOut'
                    }}
                >
                    {highlighted_items.map(({ highlighted_product }, index) => {
                        
                        const data = highlighted_product.document.data
                        const slug = `collection/${data.product_family.slug}/${highlighted_product.document.uid}`
                        
                        return (
                            <Item 
                                key={index}
                            >
                                <Waypoint
                                    onEnter={() => itemEnter(index)}
                                />
                                <StyledLink to={generatePath(lang, slug)}>
                                    <ImageWrapper>
                                        {data.large && (
                                            <StyledImage 
                                                className='image' 
                                                fluid={data.large.fluid} 
                                                alt={data.large.alt} 
                                                fadeIn={false} 
                                                loading='eager'
                                            />
                                        )}
                                    </ImageWrapper>
                                    <Product>{data.product_title.text}</Product>
                                    <Family>{data.product_family.document.data.title.text}</Family>
                                </StyledLink>
                            </Item>
                        )
                    })}
                </List>
                <Bottom className='mobile'>
                    <ButtonCircle
                        type='arrow'
                        direction='right'
                        label={highlighted_button_label}
                        to={generatePath(lang, 'collection')}
                    />
                </Bottom>
            </InView>
        </Wrapper>
    )
}

export default Featured