import React, { useState, useEffect, useRef } from "react"
import gsap from "gsap"
import styled from "styled-components"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useInView } from "react-intersection-observer"
import ReactPlayer from "react-player"

import Title from "./../Title"
import ButtonCircle from "./../Buttons/ButtonCircle"
import ButtonPlaySvg from "./../../graphics/button-play.svg"
import ButtonFullscreenSvg from "./../../graphics/button-fullscreen.svg"
import ButtonPauseSvg from "./../../graphics/button-pause.svg"

import PreviewImage from "./../../images/home/images/living-creations-preview.jpg"

const Story = styled.section`
    ${props => props.theme.below.desktop`
        margin-bottom: 0;

        min-height: auto;
    `}

    ${props => props.theme.above.desktop`
        display: flex;
        flex-direction: column;

        min-height: 400vh;

        padding-top: 5em;
    `}
`

const Header = styled.div`
    position: relative;

    width: 100%;
    /* max-width: 95rem; */
    margin: 0 auto;

    text-align: center;

    .title-wrapper {
        padding: 0;
    }

    ${props => props.theme.below.desktop`
        padding: 3rem 3rem 5rem 3rem;

        .title-wrapper:first-of-type {
            margin-bottom: 0 !important;
        }

        .button-circle {
            margin-top: 3rem;
            text-align: left;
        }
    `}

    ${props => props.theme.above.desktop`
        .button-circle {
            display: none;
        }
    `}
`

const VideoWrapper = styled.div`
    position: relative;

    width: 100%;

    ${props => props.theme.below.desktop`
        /* margin: 0 auto 5rem auto; */
    `}

    ${props => props.theme.above.desktop`
        max-width: 55vw;    
        height: 100vh;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin: 0 auto 30rem auto;
    `}
`

const StyledVideo = styled.div`
    position: relative;

    width: 100%;

    padding-top: 56.25%;

    .react-player {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        /* pointer-events: none;

        iframe {
            pointer-events: none;
        } */
    }

    ${props => props.theme.above.desktop`
        margin-bottom: 5rem;
    `}
`

const Preview = styled.div`
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(26, 25, 25, 0.8);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const PreviewBackground = styled.img`
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
`

const Controls = styled.div`
    position: absolute;

    left: 0;
    bottom: 0;

    padding: 0 4rem 3rem 4rem;

    width: 100%;

    display: flex;
    justify-content: space-between;
    align-content: center;

    @media screen and (max-width: 1024px) {
        padding: 0 2rem 2rem 2rem;
    }
`

const PreviewPlay = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    margin-top: 15rem;

    cursor: pointer;

    @media screen and (max-width: 1024px) {
        margin-top: 7.5rem;
    }
`

const PreviewText = styled.p`
    margin-left: 2rem;

    font-family: ${props => props.theme.fontFamily.neueMontrealMedium};
    letter-spacing: 0.12rem;
    font-size: 1.5rem;

    color: #fff;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`

const ButtonPlay = styled(ButtonPlaySvg)`
    width: 1.5rem;
`

const Pause = styled.button`
    position: relative;
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        left: -2rem;
        top: -1rem;
        right: -2rem;
        bottom: -1rem;
    }
`

const ButtonPause = styled(ButtonPauseSvg)`
    width: 1.25rem;

    @media screen and (max-width: 1024px) {
        width: 1rem;
    }
`

const Fullscreen = styled.a`
    position: absolute;

    top: 4rem;
    right: 4rem;

    @media screen and (max-width: 1024px) {
        top: 1.5rem;
        right: 1.5rem;
    }
`

const ButtonFullscreen = styled(ButtonFullscreenSvg)`
    width: 1.5rem;
    height: 1.5rem;

    @media screen and (max-width: 1024px) {
        width: 1rem;
        height: 1rem;
    }
`

const Progress = styled.div`
    font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
    letter-spacing: 0.12rem;
    font-size: 1.25rem;

    @media screen and (max-width: 1024px) {
        font-size: 1rem;
    }
`

const FirstTitle = styled(Title)``

const SectionStory = ({ data }) => {
    const STORY = useRef(null)
    const VIDEO = useRef(null)
    const HEADER = useRef(null)

    const [videoReady, setVideoReady] = useState(false)
    const [duration, setDuration] = useState(0)
    const [progress, setProgress] = useState(0)
    const [timeLeft, setTimeLeft] = useState(0)

    const [videoRef, videoInView] = useInView({
        triggerOnce: false,
        threshold: 0.25,
    })

    const timeline = () =>
        gsap
            .timeline({
                scrollTrigger: {
                    trigger: STORY.current,
                    start: "top top",
                    end: () => "bottom bottom",
                    scrub: true,
                    pin: STORY.current,
                    pinSpacing: false,
                    anticipatePin: 1,
                },
            })
            .set(VIDEO.current, {
                scale: 1.1,
                autoAlpha: 0,
            })
            .set(HEADER.current, {
                yPercent: "10",
                autoAlpha: 0,
            })
            .to(
                VIDEO.current,
                {
                    scale: 1,
                    autoAlpha: 1,
                    duration: 0.1,
                },
                "together"
            )
            .to(
                HEADER.current,
                {
                    yPercent: "0",
                    autoAlpha: 1,
                    duration: 0.1,
                },
                "together"
            )
            .to(
                VIDEO.current,
                {
                    scale: 1.1,
                    autoAlpha: 0,
                    delay: 1,
                },
                "out"
            )
            .to(
                HEADER.current,
                {
                    scale: 1.1,
                    autoAlpha: 0,
                    yPercent: "30",
                    delay: 1,
                },
                "out"
            )

    useEffect(() => {
        var sec_num = duration - progress.playedSeconds
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor((sec_num - hours * 3600) / 60)
        var seconds = Math.round(sec_num - hours * 3600 - minutes * 60)

        if (hours < 10) {
            hours = "0" + hours
        }
        if (minutes < 10) {
            minutes = "0" + minutes
        }
        if (seconds < 10) {
            seconds = "0" + seconds
        }
        setTimeLeft(minutes + ":" + seconds)
    }, [progress])

    useEffect(() => {
        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger)
        }

        gsap.defaults({
            ease: "none",
        })

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function() {
                timeline()
            },
        })
    }, [])

    useEffect(() => {
        if (videoInView && !videoReady) {
            setVideoReady(true)
        }
    }, [videoInView])

    return (
        <Story ref={STORY} className="section-story">
            {/* <Header>
                <FirstTitle title="The creation" alignment="left" scroll text />
                <Title
                    description="Everything we do is devoted to create and grow long-lasting Hydrangeas. We love to take you with us on a trip of observing, creating and growing. Get introduced to the world behind our Hydrangeas."
                    alignment="left"
                    scroll
                    text
                />
                <ButtonCircle
                    type="arrow"
                    direction="right"
                    label="Shop online"
                    href="https://shop.livingcreations.com/en/shop-2/"
                />
            </Header> */}
            <VideoWrapper ref={videoRef}>
                <StyledVideo ref={VIDEO}>
                    <ReactPlayer
                        url="https://www.youtube.com/watch?v=xrwCmBsuudw"
                        width="100%"
                        height="100%"
                        playing={videoReady}
                        muted={true}
                        allowFullScreen="allowFullScreen"
                        frameBorder="0"
                        controls={true}
                        className="react-player"
                        // playsinline={true}
                        // loop={true}
                        // onDuration={e => setDuration(e)}
                        // onProgress={e => setProgress(e)}
                        // config={{
                        //     youtube: {
                        //         playerVars: {
                        //             modestbranding: 1,
                        //             fs: 0,
                        //             disablekb: 1,
                        //         },
                        //     },
                        // }}
                    />
                    {/* {!videoReady && (
                        <Preview onClick={() => setVideoReady(true)}>
                            <PreviewBackground
                                src={PreviewImage}
                                alt="Living Creations Video"
                            />
                            <PreviewPlay>
                                <ButtonPlay />
                                <PreviewText>{data.playLabel}</PreviewText>
                            </PreviewPlay>
                        </Preview>
                    )} */}
                    {/* {videoReady && (
                        <>
                            <Fullscreen
                                href="https://vimeo.com/715310423"
                                target="_blank"
                            >
                                <ButtonFullscreen />
                            </Fullscreen>
                            <Controls>
                                <Pause onClick={() => setVideoReady(false)}>
                                    <ButtonPause />
                                </Pause>
                                <Progress>{timeLeft}</Progress>
                            </Controls>
                        </>
                    )} */}
                </StyledVideo>
                <Header ref={HEADER}>
                    <Title
                        description={data.description}
                        alignment="center"
                        html
                        scroll
                    />
                    <ButtonCircle
                        type="cart"
                        label={data.buttonLabel}
                        href="https://shop.livingcreations.com/en/shop-2/"
                    />
                </Header>
            </VideoWrapper>
        </Story>
    )
}

export default SectionStory
