import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import gsap from "gsap"
import Img from "gatsby-image"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import styled from "styled-components"

import Title from "./../Title"

const SectionLiving = ({ data }) => {
    const LIVING_SECTION = useRef(null)
    const LIVING_SECTION_IMAGE = useRef(null)
    const LIVING_SECTION_FIRST_TEXT = useRef(null)
    const LIVING_SECTION_SECOND_TEXT = useRef(null)

    const { living } = useStaticQuery(graphql`
        query {
            living: allFile(
                filter: {
                    relativePath: { in: ["home/images/section-living.png"] }
                }
            ) {
                edges {
                    node {
                        name
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
    `)

    // console.log(living)

    const timeline = () =>
        gsap
            .timeline({
                scrollTrigger: {
                    trigger: LIVING_SECTION.current,
                    start: "top top",
                    end: () => "+=215%",
                    scrub: true,
                    pin: true,
                    pinSpacing: false,
                    anticipatePin: 1,
                },
            })
            .set(LIVING_SECTION_IMAGE.current, {
                xPercent: "-10",
                yPercent: "30",
                scale: 1.2,
                transformOrigin: "center top",
            })
            // .set(LIVING_SECTION_FIRST_TEXT.current.querySelector('.gradient-opacity-text'), {
            //     backgroundPosition: "0 0",
            // })
            .set(LIVING_SECTION_SECOND_TEXT.current, {
                autoAlpha: 0,
                yPercent: "10",
            })
            .to(LIVING_SECTION_IMAGE.current, {
                scale: 1,
                xPercent: "-10",
                duration: "0.1",
            })
            // .to(LIVING_SECTION_FIRST_TEXT.current.querySelector('.gradient-opacity-text'), {
            //     backgroundPosition: "0 50%",
            //     duration: "0.1"
            // }, '-=0.1')
            .to(
                LIVING_SECTION_FIRST_TEXT.current,
                {
                    autoAlpha: 0,
                    duration: "0.1",
                },
                "-=0.1"
            )
            .to(LIVING_SECTION_SECOND_TEXT.current, {
                autoAlpha: 1,
                yPercent: "0",
                duration: "0.2",
            })
            .to(
                LIVING_SECTION_IMAGE.current,
                {
                    yPercent: "10",
                    duration: "0.15",
                },
                "-=0.2"
            )
            // .set(LIVING_SECTION_SECOND_TEXT.current.querySelector('.gradient-opacity-text'), {
            //     autoAlpha: 0.5,
            //     backgroundPosition: "0 400%"
            // })
            // .to(LIVING_SECTION_SECOND_TEXT.current.querySelector('.gradient-opacity-text'), {
            //     backgroundPosition: "0 300%",
            //     duration: "0.1"
            // })
            // .to(LIVING_SECTION_SECOND_TEXT.current.querySelector('.gradient-opacity-text'), {
            //     autoAlpha: 1,
            //     duration: "0.1"
            // })
            // .to(LIVING_SECTION_IMAGE.current, {
            //     yPercent: "-10",
            //     duration: "0.1"
            // })
            .to(
                LIVING_SECTION_IMAGE.current,
                {
                    yPercent: "-50",
                    autoAlpha: 0,
                    duration: "0.5",
                },
                "go away"
            )
            .to(
                LIVING_SECTION_SECOND_TEXT.current,
                {
                    yPercent: "-100",
                    autoAlpha: 0,
                    duration: "0.5",
                },
                "go away"
            )

    useEffect(() => {
        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger)
        }

        gsap.defaults({
            ease: "none",
        })

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function() {
                setTimeout(timeline, 100)
            },
        })

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill())
        }
    }, [])

    return (
        <section className="section-living">
            <div className="section-living__wrapper is-mobile">
                <div className="section-mobile__image-overlay">
                    <Img
                        className="picture-image picture-living"
                        fluid={living.edges[0].node.childImageSharp.fluid}
                    />
                </div>
                <Title
                    subtitle={data.subtitle}
                    title={data.title}
                    description={data.description}
                    alignment="left"
                    scroll
                    htmlTitle
                />
            </div>
            <div
                ref={LIVING_SECTION}
                className="section-living__wrapper is-desktop"
            >
                <div
                    ref={LIVING_SECTION_FIRST_TEXT}
                    className="section-living__text"
                >
                    <Title
                        subtitle={data.subtitle}
                        title={data.title}
                        alignment="left"
                        scroll
                        htmlTitle
                    />
                </div>
                <div className="flex-wrapper">
                    <div
                        ref={LIVING_SECTION_SECOND_TEXT}
                        className="section-trust__text"
                    >
                        <Title
                            title={data.contentTitle}
                            description={data.description}
                            alignment="left"
                            scroll
                        />
                    </div>
                    <div
                        ref={LIVING_SECTION_IMAGE}
                        className="section-living__decor"
                    >
                        <Img
                            className="picture-image picture-living"
                            fluid={living.edges[0].node.childImageSharp.fluid}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionLiving
