import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import Title from './../Title'
import { generatePath } from '../../utils/helpers'
import Card from './Card'
import { Current } from '../Shared'

const Wrapper = styled.div`
    padding: 0 0 3rem 0;
`

const StyledCarousel = styled(motion.div)``

const StyledTitle = styled(Title)`
    margin: 3rem 0 2rem 0;
`

const StyledCurrent = styled(Current)`
    display: block;

    padding: 0 0 3rem 3rem;
    
    .line {
        display: none;
    }

    .label {
        margin: 0;
    }
`

class Carousel extends React.Component {

    state = {
        inView: false,
        currentIndex: 0,
        responsive: {
            0: {
                items: 1
            }
        },
        stagePadding: {
            paddingLeft: 30,
            paddingRight: 30
        }
    }

    onSlideChanged = (e) => this.setState({ currentIndex: e.item })

    render() {
        
        const {
            lang,
            className,
            title,
            description,
            items
        } = this.props

        const { 
            responsive, 
            currentIndex,
            stagePadding 
        } = this.state

        return (
            <InView threshold={0.15} triggerOnce={true} onChange={(inView, entry) => this.setState({inView: inView})}>
                <Wrapper className={className}>
                    <StyledTitle 
                        title={title.text}
                        description={description.raw}
                    />
                    <StyledCurrent 
                        total={items.edges.length} 
                        current={currentIndex + 1} 
                        animation={this.state.inView ? 'after' : 'before'}
                        variants={{
                            before: {
                                opacity: 0,
                                x: -25
                            },
                            after: {
                                opacity: 1,
                                x: 0
                            }
                        }}
                        transition={{
                            duration: 0.35,
                            delay: 0.35,
                            damping: 100,
                            stiffness: 50,
                            ease: 'circOut'
                        }}
                    />
                    <StyledCarousel
                        animate={this.state.inView ? 'after' : 'before'}
                        variants={{
                            before: {
                                opacity: 0
                            },
                            after: {
                                opacity: 1
                            }
                        }}
                        transition={{
                            duration: 0.35,
                            damping: 100,
                            stiffness: 50,
                            ease: 'circOut'
                        }}
                    >
                        <AliceCarousel
                            buttonsDisabled={true}
                            dotsDisabled={true}
                            duration={500}
                            stagePadding={stagePadding}
                            responsive={responsive}
                            slideToIndex={currentIndex}
                            onSlideChanged={this.onSlideChanged}
                        >
                            {items.edges.map(({ node: { uid, data } }, index) =>
                                <Card
                                    to={generatePath(lang, `about/${uid}`)}
                                    key={index}
                                    data={data}
                                />
                            )}
                        </AliceCarousel>
                    </StyledCarousel>
                </Wrapper>
            </InView>
        )
    }
}

export default Carousel
