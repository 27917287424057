import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { generatePath } from "./../../utils/helpers"

import Title from "./../Title"
import ButtonCircle from "./../Buttons/ButtonCircle"

const Section = styled.section`
    min-height: 200vh;

    ${props => props.theme.below.desktop`
        min-height: auto;
    `}
`

const Wrapper = styled.div`
    ${props => props.theme.above.desktop`
        height: 100vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `}

    ${props => props.theme.below.desktop`
        margin-bottom: 6rem;
    `}
`

const TitleWithButtonWrapper = styled.div`
    text-align: center;

    .title-wrapper {
        margin-bottom: 2.5rem;
    }

    .button-circle {
        display: inline-block;
    }

    ${props => props.theme.below.desktop`
        margin-top: 2.5rem;

        text-align: center;
        visibility: visible;
        
        .title-wrapper {
            text-align: center;
        }
    `}

    ${props => props.theme.above.desktop`
        /* margin-bottom: 15rem; */

        .title-wrapper {
            max-width: 65rem;
        }
    `}
`

const TitleWrapper = styled.div`
    position: relative;

    /* visibility: hidden; */
`

const ImageWrapper = styled.div`
    position: relative;

    width: 30em;

    margin: 0 auto 6em auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${props => props.theme.below.desktop`
        width: 15em;

        margin: 0 auto 3em auto;
    `}
`

const Image = styled.img`
    position: absolute;

    width: 25em;

    transform-origin: center;

    &.center {
        position: relative;

        width: 100%;

        z-index: 1;
    }

    &.left {
        top: 50%;
    }

    &.right {
        top: 50%;
    }

    ${props => props.theme.below.desktop`
        width: 12em;

        &.center {
            transform: rotate(5deg) !important;
        }

        &.left {
            top: 50%;
            left: -50%;

            transform: translateY(-50%) rotate(-5deg) !important;
        }

        &.right {
            top: 50%;
            right: -50%;

            transform: translateY(-50%) rotate(5deg) !important;
        }
    `}
`

const SectionCollection = ({ lang, data }) => {
    const COLLECTION = useRef(null)
    const LEFT_IMAGE = useRef(null)
    const CENTER_IMAGE = useRef(null)
    const RIGHT_IMAGE = useRef(null)
    const TITLE = useRef(null)
    const BUTTON = useRef(null)

    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5,
    })

    const timeline = () =>
        gsap
            .timeline({
                scrollTrigger: {
                    trigger: COLLECTION.current,
                    start: "top top",
                    end: () => "bottom bottom",
                    scrub: true,
                    pin: COLLECTION.current,
                    pinSpacing: false,
                    anticipatePin: 1,
                },
            })
            .set(LEFT_IMAGE.current, {
                translateY: "-50%",
                autoAlpha: 0,
            })
            .set(CENTER_IMAGE.current, {
                autoAlpha: 0,
            })
            .set(RIGHT_IMAGE.current, {
                translateY: "-50%",
                autoAlpha: 0,
            })
            .set(TITLE.current, {
                autoAlpha: 0,
                yPercent: "-10",
            })
            .set(BUTTON.current, {
                autoAlpha: 0,
            })
            .to(CENTER_IMAGE.current, {
                autoAlpha: 1,
                duration: 0.1,
            })
            .set(LEFT_IMAGE.current, {
                autoAlpha: 1,
            })
            .set(RIGHT_IMAGE.current, {
                autoAlpha: 1,
            })
            .to(
                LEFT_IMAGE.current,
                {
                    translateY: "-50%",
                    translateX: "-80%",
                    rotation: "-5",
                },
                "together"
            )
            .to(
                CENTER_IMAGE.current,
                {
                    rotation: "5",
                },
                "together"
            )
            .to(
                RIGHT_IMAGE.current,
                {
                    translateY: "-50%",
                    translateX: "80%",
                    rotation: "5",
                },
                "together"
            )
            .to(
                TITLE.current,
                {
                    autoAlpha: 1,
                    yPercent: "0",
                    duration: "0.1",
                },
                "together"
            )
            .to(
                BUTTON.current,
                {
                    autoAlpha: 1,
                },
                "together"
            )

    useEffect(() => {
        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger)
        }

        gsap.defaults({
            ease: "none",
        })

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function() {
                timeline()
            },
        })
    }, [])

    return (
        <Section className="section-collection" ref={COLLECTION}>
            <div ref={ref}>
                <Wrapper>
                    <ImageWrapper>
                        <Image
                            ref={LEFT_IMAGE}
                            className="left"
                            src="../../images/collection/collection-left.jpg"
                            alt="Living Creations Collection Image"
                        />
                        <Image
                            ref={CENTER_IMAGE}
                            className="center"
                            src="../../images/collection/collection-center.jpg"
                            alt="Living Creations Collection Image"
                        />
                        <Image
                            ref={RIGHT_IMAGE}
                            className="right"
                            src="../../images/collection/collection-right.jpg"
                            alt="Living Creations Collection Image"
                        />
                    </ImageWrapper>
                    <TitleWithButtonWrapper>
                        <TitleWrapper ref={TITLE}>
                            <Title
                                title={data.title}
                                alignment="center"
                                scroll
                                htmlTitle
                            />
                        </TitleWrapper>
                        <div ref={BUTTON}>
                            <ButtonCircle
                                type="arrow"
                                direction="right"
                                label={data.buttonLabel}
                                // animation={inView ? "after" : "before"}
                                to={generatePath(lang, `collection`)}
                                alignment="center"
                            />
                        </div>
                    </TitleWithButtonWrapper>
                </Wrapper>
            </div>
        </Section>
    )
}

export default SectionCollection
