import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import gsap from "gsap"
import styled from "styled-components"
import Img from "gatsby-image"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import VideoHR from "./../../images/videos/easy_to_maintain_HR.mp4"
import VideoLR from "./../../images/videos/easy_to_maintain_LR.mp4"

import Title from "./../Title"

const SectionCare = ({ data }) => {
    const CARE_SECTION = useRef(null)
    const CARE_SECTION_WRAPPER = useRef(null)
    const CARE_SECTION_TEXT = useRef(null)
    const CARE_SECTION_IMAGE = useRef(null)
    const CARE_SECTION_DECOR = useRef(null)

    const { care } = useStaticQuery(graphql`
        query {
            care: allFile(
                filter: {
                    relativePath: { in: ["home/images/section-care.jpg"] }
                }
            ) {
                edges {
                    node {
                        name
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)

    const timeline = () =>
        gsap
            .timeline({
                scrollTrigger: {
                    trigger: CARE_SECTION_WRAPPER.current,
                    start: "top top",
                    end: () => "+=175%",
                    scrub: true,
                    pin: true,
                    pinSpacing: false,
                    anticipatePin: 1,
                },
            })
            .set(CARE_SECTION_TEXT.current, {
                yPercent: "10",
            })
            .to(CARE_SECTION_TEXT.current, {
                autoAlpha: 0.5,
                yPercent: "0",
                duration: "0.1",
            })
            .to(CARE_SECTION_TEXT.current, {
                autoAlpha: 1,
                duration: "0.3",
            })
            .set(CARE_SECTION_IMAGE.current, {
                xPercent: "-100",
            })
            .to(CARE_SECTION_IMAGE.current, {
                autoAlpha: 1,
                duration: "0.03",
            })
            .to(CARE_SECTION_IMAGE.current, {
                xPercent: "0",
                duration: "1.5",
            })
            .set(CARE_SECTION_TEXT.current, {
                autoAlpha: 0,
            })
            .to(CARE_SECTION_IMAGE.current, {
                xPercent: "100",
                autoAlpha: "0",
                duration: "1.5",
            })
            .to(
                CARE_SECTION_DECOR.current,
                {
                    x: "99%",
                    duration: "0.5",
                },
                "-=1.5"
            )
            .set(CARE_SECTION.current, {
                overflow: "hidden",
            })

    useEffect(() => {
        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger)
        }

        gsap.defaults({
            ease: "none",
        })

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function() {
                timeline()
            },
        })
    }, [])

    return (
        <section ref={CARE_SECTION} className="section-care">
            <div className="is-mobile">
                <div className="mobile-video">
                    <video
                        className="full-video"
                        autoPlay
                        playsInline
                        muted
                        loop
                        style={{
                            width: "100%",
                            height: "100%",
                            minWidth: "100%",
                            minHeight: "100%",
                        }}
                    >
                        <source src={VideoLR} type="video/mp4" />
                    </video>
                </div>
                <div className="section-care__wrapper">
                    <Title
                        title={data.title}
                        description={data.description}
                        alignment="left"
                        scroll
                    />
                </div>
            </div>
            <div
                ref={CARE_SECTION_WRAPPER}
                className="section-care__wrapper is-desktop"
            >
                <div ref={CARE_SECTION_IMAGE} className="section-care__decor">
                    <div
                        ref={CARE_SECTION_DECOR}
                        className="section-care__decor-left-shadow"
                    ></div>
                    <div className="picture-image picture-image__full picture-care">
                        <video
                            className="full-video"
                            autoPlay
                            playsInline
                            muted
                            loop
                            style={{
                                width: "100%",
                                height: "100%",
                                minWidth: "100%",
                                minHeight: "100%",
                            }}
                        >
                            <source src={VideoHR} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div ref={CARE_SECTION_TEXT} className="section-care__text">
                    <Title
                        title={data.title}
                        description={data.description}
                        alignment="left"
                        scroll
                    />
                </div>
            </div>
        </section>
    )
}

export default SectionCare
