import React from 'react'
import { useInView, InView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { HorizontalScroll } from './../Shared'
import Card from './Card'
import CardIntro from './CardIntro'
import Carousel from './Carousel'

import { generatePath } from '../../utils/helpers'

const Wrapper = styled(motion.section)`
    position: relative;
`

const HorizontalSection = styled(motion.section)`
    position: relative;
    
    width: 100%;
    min-height: 100vh;
`

const CardsContainer = styled(motion.div)`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    
    height: 100%;
`

const AboutPage = ({
    lang,
    className,
    items,
    sectionInView,
    inViewTreshold,
    data: {
        content_title, 
        content_description
    }
}) => {

    const [ref, wrapperInView] = useInView({
        threshold: 0.15,
        triggerOnce: true
    })

    return (
        <Wrapper 
            ref={ref} 
            className={className} 
            name='about'
        >
            <InView treshold={inViewTreshold} onChange={(inView) => inView && sectionInView && sectionInView()}>
                {/* <Carousel 
                    lang={lang}
                    className='mobile' 
                    title={content_title} 
                    description={content_description}
                    items={items} 
                /> */}
                <HorizontalSection>
                    <HorizontalScroll>
                        <CardsContainer 
                            className='cards-container'
                            animate={wrapperInView ? 'after' : 'before'}
                            variants={{
                                before: {},
                                after: {
                                    transition: {
                                        staggerChildren: 0.15,
                                    }
                                }
                            }}
                        >
                            <CardIntro 
                                key={0} 
                                title={content_title.text} 
                                description={content_description.html}
                            />
                            {items.edges.map(({ node: { data, uid } }, index) => {
                                return (
                                    <Card 
                                        key={index + 1}
                                        to={generatePath(lang, `about/${uid}`)}  
                                        data={data}
                                    />
                                )
                            })}
                        </CardsContainer>
                    </HorizontalScroll>
                </HorizontalSection>
            </InView>
        </Wrapper>
    )
}

export default AboutPage
