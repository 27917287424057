import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { scroller } from "react-scroll"
import Title from "./../Title"
import ButtonCircle from "./../Buttons/ButtonCircle"

const FirstTitle = styled(Title)``

const Buy = styled.section`
    min-height: 200vh;

    ${props => props.theme.below.desktop`
        padding: 0 5rem;
        margin: 0 auto 10rem auto;

        display: none;
    `}
`

const Wrapper = styled.div`
    ${props => props.theme.above.desktop`
        height: 100vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `}
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title-wrapper {
        max-width: 60rem;
        margin-bottom: 3rem;
    }

    ${props => props.theme.below.desktop`
        .title-wrapper {
            text-align: center;
        }
    `}
`

const SectionBuy = ({ data }) => {
    const BUY = useRef(null)
    const TITLE = useRef(null)
    const BUTTON = useRef(null)

    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5,
    })

    const timeline = () =>
        gsap
            .timeline({
                scrollTrigger: {
                    trigger: BUY.current,
                    start: "top top",
                    end: () => "bottom bottom",
                    scrub: true,
                    pin: BUY.current,
                    pinSpacing: false,
                    anticipatePin: 1,
                },
            })
            .set(TITLE.current, {
                autoAlpha: 0,
                yPercent: "-10",
            })
            .set(BUTTON.current, {
                autoAlpha: 0,
            })
            .to(
                TITLE.current,
                {
                    autoAlpha: 1,
                    yPercent: "0",
                    duration: "0.1",
                },
                "together"
            )
            .to(
                BUTTON.current,
                {
                    autoAlpha: 1,
                },
                "together"
            )

    useEffect(() => {
        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger)
        }

        gsap.defaults({
            ease: "none",
        })

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function () {
                timeline()
            },
        })
    }, [])

    return (
        <Buy ref={BUY}>
            <Wrapper ref={ref}>
                <TitleWrapper>
                    <div ref={TITLE}>
                        <FirstTitle
                            title={data.title}
                            description={data.description}
                            alignment="center"
                            text
                        />
                    </div>
                    <div ref={BUTTON}>
                        <ButtonCircle
                            type="arrow"
                            label={data.buttonLabel}
                            animation={inView ? "after" : "before"}
                            onClick={() => {
                                scroller.scrollTo("instagram", {
                                    duration: 1500,
                                    delay: 10,
                                    smooth: "easeInOutQuint",
                                })
                            }} />
                    </div>
                </TitleWrapper>
            </Wrapper>
        </Buy>
    )
}

export default SectionBuy
