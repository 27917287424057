import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import gsap from "gsap"
import Img from "gatsby-image"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import styled from "styled-components"

import VideoHR from "./../../images/videos/severe_winters_HR.mp4"
import VideoLR from "./../../images/videos/severe_winters_LR.mp4"

import Title from "./../Title"

const SectionWinter = ({ data }) => {
    const WINTER_SECTION_WRAPPER = useRef(null)
    const WINTER_SECTION_IMAGE = useRef(null)
    const WINTER_SECTION_TEXT = useRef(null)
    const WINTER_SECTION_IMAGE_BEFORE = useRef(null)
    const WINTER_SECTION_IMAGE_AFTER = useRef(null)

    const { winter } = useStaticQuery(graphql`
        query {
            winter: allFile(
                filter: {
                    relativePath: { in: ["home/images/section-winter.jpg"] }
                }
            ) {
                edges {
                    node {
                        name
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)

    const timeline = () =>
        gsap
            .timeline({
                scrollTrigger: {
                    trigger: WINTER_SECTION_WRAPPER.current,
                    start: "top top",
                    end: () => "+=100%",
                    scrub: true,
                    pin: true,
                    pinSpacing: false,
                    anticipatePin: 1,
                },
            })
            // .set(WINTER_SECTION_IMAGE_BEFORE.current, {
            //     y: "-30%"
            // })
            // .set(WINTER_SECTION_IMAGE_AFTER.current, {
            //     y: "-30%"
            // })
            // .set(WINTER_SECTION_TEXT.current.querySelector('.gradient-opacity-text--both'), {
            //     backgroundPosition: "0 -30%"
            // })
            .to(WINTER_SECTION_TEXT.current, {
                autoAlpha: "1",
                yPercent: "0",
                duration: "1",
            })
            .to(WINTER_SECTION_IMAGE.current, {
                autoAlpha: "1",
                yPercent: "0",
                duration: "2",
            })
            // .to(WINTER_SECTION_TEXT.current.querySelector('.gradient-opacity-text--both'), {
            //     backgroundPosition: "0 10%",
            //     duration: "1"
            // }, "-=1")
            // .to(WINTER_SECTION_TEXT.current.querySelector('.gradient-opacity-text--both'), {
            //     backgroundPosition: "0 45%",
            //     duration: "0.2"
            // })
            // .to(WINTER_SECTION_IMAGE_BEFORE.current, {
            //     y: "-100%",
            //     duration: "0.2"
            // }, "-=0.2")
            // .to({}, {
            //     duration: "0.2"
            // })
            // .to(WINTER_SECTION_IMAGE_AFTER.current, {
            //     bottom: "-10%",
            //     duration: "0.05"
            // })
            .to(WINTER_SECTION_IMAGE.current, {
                yPercent: "-100",
                autoAlpha: 0.0,
                duration: "4",
            })
    // .to(WINTER_SECTION_TEXT.current.querySelector('.gradient-opacity-text--both'), {
    //     backgroundPosition: "0 140%",
    //     duration: "0.7"
    // }, "-=1");

    useEffect(() => {
        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger)
        }

        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function() {
                gsap.set(WINTER_SECTION_IMAGE.current, {
                    yPercent: "80",
                    autoAlpha: 0,
                })
                gsap.set(WINTER_SECTION_TEXT.current, {
                    yPercent: "10",
                    autoAlpha: 0,
                })

                gsap.defaults({
                    ease: "none",
                })
                timeline()
            },
        })
    }, [])

    return (
        <section className="section-winter">
            <div className="is-mobile">
                <div className="mobile-video">
                    <video
                        className="full-video"
                        autoPlay
                        playsInline
                        muted
                        loop
                        style={{
                            width: "100%",
                            height: "100%",
                            minWidth: "100%",
                            minHeight: "100%",
                        }}
                    >
                        <source src={VideoLR} type="video/mp4" />
                    </video>
                </div>
                <div className="section-winter__wrapper">
                    <Title
                        title={data.title}
                        description={data.description}
                        alignment="left"
                        scroll
                        header
                        text
                    />
                </div>
            </div>
            <div
                ref={WINTER_SECTION_WRAPPER}
                className="section-winter__wrapper is-desktop"
            >
                <div ref={WINTER_SECTION_TEXT} className="section-winter__text">
                    <Title
                        title={data.title}
                        description={data.description}
                        alignment="left"
                        scroll
                        header
                        text
                    />
                </div>
                <div
                    ref={WINTER_SECTION_IMAGE}
                    className="section-winter__decor"
                >
                    <div className="picture-image picture-image__full picture-care">
                        <video
                            className="full-video"
                            autoPlay
                            playsInline
                            muted
                            loop
                            style={{
                                width: "100%",
                                height: "100%",
                                minWidth: "100%",
                                minHeight: "100%",
                            }}
                        >
                            <source src={VideoHR} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionWinter
