import React, { useContext } from 'react'
import styled from 'styled-components'
import { animateScroll as scroll } from 'react-scroll'
import { motion } from 'framer-motion'

import TitleWithButton from './../TitleWithButton'
import { DictionaryContext } from '../../contexts/dictionary'

const StyledCard = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    flex-shrink: 0;
    
    position: relative;

    width: 100vw;
    max-width: 45rem;
    
    ${props => props.theme.below.desktop`
        margin-right: 6rem;

        .button-circle {
            display: block;
        }
    `}

    ${props => props.theme.above.desktop`
        width: 40rem;
        max-width: none;

        margin: -10rem 25rem 0 25rem;
    `}
`

const CardIntro = ({
    lang,
    title, 
    description,
    className,
    animate
}) => {
    
    const dictionary = useContext(DictionaryContext)

    return (
        <StyledCard 
            className={className}
            animate={animate}
            initial={{
                opacity: 0
            }}
            variants={{
                before: {
                    opacity: 0,
                    y: 100,
                },
                after: {
                    opacity: 1,
                    y: 0
                }
            }}
            transition={{
                duration: 1,
                damping: 100,
                stiffness: 100,
                ease: 'circOut'
            }}
        >
            <TitleWithButton
                lang={lang}
                title={title}
                description={description}
                button={{
                    type: 'arrow',
                    direction: 'down',
                    label: dictionary.scroll_down,
                    onClick: () => scroll.scrollMore(800)
                }}
            />
        </StyledCard>
    )
}

export default CardIntro