import React, { useState, useEffect, useContext, useRef } from "react"
import { useInView, InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import styled from "styled-components"

import { Socials, InlineVideo, EmbedVideo, YoutubeVideo } from "./Shared"
import { PreloaderContext } from "./../contexts/preloader"
import LanguageSwitch from "./LanguageSwitch"

const Banner = styled.section`
    position: relative;

    width: 100%;
    height: 100vh;
    /* height: calc(var(--vh, 1vh) * 100); */

    overflow: hidden;

    .swiper-container {
        z-index: 0;
    }

    ${props => props.theme.below.desktop`
        height: calc(var(--vh, 1vh) * 100);

        max-height: 800px;
    `}
`

const StyledInlineVideo = styled(InlineVideo)`
    width: 100%;
    height: 100%;
`

const StyledEmbedVideo = styled(EmbedVideo)`
    width: 100%;
    height: 100%;
`

const StyledSocials = styled(Socials)`
    display: none;

    ${props => props.theme.above.desktop`
        display: block;

        position: absolute;

        right: 6rem;
        bottom: 6rem;
    `}
`

const TitleWrapper = styled.div`
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    z-index: 1;
    overflow: hidden;

    width: 100%;

    padding: 0 3rem;

    text-align: center;

    ${props => props.theme.above.desktop`
        height: 30rem;
        padding: 0;

        transform: translate(-50%, -50%);
    `}
`

const Title = styled(motion.h1)`
    position: relative;

    max-width: 100rem;
    margin: 0 auto;

    font-family: MinionSemibold;
    font-size: 5rem;
    line-height: 5rem;
    letter-spacing: 0.15rem;

    color: ${props => props.theme.colors.white};

    ${props => props.theme.above.desktop`
        top: 2.5rem;

        font-size: 12.5rem;
        line-height: 13rem;
        letter-spacing: 0.25rem;
    `}
`

const LanguageSwitchWrapper = styled.div`
    position: absolute;

    width: 20rem;

    right: 5rem;
    bottom: 5rem;

    z-index: 1;

    ${props => props.theme.below.desktop`
        display: none;
    `}
`

const HeroBanner = ({ title, social, slideshow }) => {
    const [videos, setVideos] = useState(slideshow[0].items)
    const [randomVideo, setRandomVideo] = useState(null)

    // Context
    const preloaderState = useContext(PreloaderContext)
    const delay = preloaderState == "preloader" ? 3 : 1.25

    const heroRef = useRef(null)

    // const [heroRef, heroInView] = useInView({
    //     triggerOnce: true
    // })

    // const animation = heroInView ? 'after' : 'before'

    useEffect(() => {
        setRandomVideo(videos[Math.floor(Math.random() * videos.length)])
    }, [])

    // useEffect(() => {

    //     let vh = typeof window !== 'undefined' ? window.innerHeight * 0.01 : 1000
    //     heroRef.current.style.setProperty('--vh', `${vh}px`)

    // }, [])

    return (
        // <InView treshold={inViewTreshold} onChange={(inView, entry) => inView && sectionInView && sectionInView()}>
        <Banner ref={heroRef} name="banner">
            {randomVideo && (
                <YoutubeVideo
                    type="banner"
                    data={randomVideo.video.document.data}
                />
            )}
            <TitleWrapper>
                <Title
                    animate="after"
                    initial="before"
                    variants={{
                        before: {
                            y: 100,
                            skewY: 10,
                            opacity: 0,
                        },
                        after: {
                            y: 0,
                            skewY: 0,
                            opacity: 1,
                        },
                    }}
                    transition={{
                        duration: 1,
                        damping: 50,
                        delay: delay,
                        ease: "circOut",
                    }}
                >
                    {title}
                </Title>
            </TitleWrapper>
            <LanguageSwitchWrapper>
                <LanguageSwitch />
            </LanguageSwitchWrapper>
            <StyledSocials data={social} animation="after" />
        </Banner>
        // </InView>
    )
}

export default HeroBanner
